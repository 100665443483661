import moment from 'moment';
import Error from 'next/error';
import Head from 'next/head';
import 'moment/locale/zh-cn';
import Router from 'next/router';
import * as Sentry from '@sentry/browser';

import '~/styles/antd/index.css';

import ProtectRoute from '~/components/ProtectRoute';
import * as gtag from '~/utils/gtag';
import '~/styles/index.css';

moment.locale('zh-cn');

Sentry.init({
  dsn: 'https://95db20fcac304e22950e1dd646c5df72@o199008.ingest.sentry.io/5505212',
  enabled: process.env.NODE_ENV === 'production',
});

Router.events.on('routeChangeComplete', (url) => gtag.pageview(url));

function MyApp({ Component, pageProps }) {
  if (pageProps.error) {
    return <Error statusCode={pageProps.error.statusCode} title={pageProps.error.message} />;
  }
  return (
    <ProtectRoute>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
      </Head>
      <Component {...pageProps} />
    </ProtectRoute>
  );
}

export default MyApp;
