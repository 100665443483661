import React from 'react';
import { Skeleton } from 'antd';
import _ from 'lodash';

import { useAuth } from '~/hooks/withAuth';
import Layout from '~/components/Layout';
import { loginRequiredPages } from '~/utils/seo_map';
import UnauthorizedView from './UnauthorizedView';

const ProtectRoute = ({ children }) => {
  const { isLogin, isLoading, isActive, hasProfile, isReviewing, isVip, isBan } = useAuth();

  if (process.browser) {
    if (isBan) {
      return <UnauthorizedView name="ban" />;
    }
    const isLoginRequired = _.find(loginRequiredPages, (prefix) => window.location.pathname.startsWith(prefix));
    const isSettingPage = window.location.pathname.startsWith('/settings');
    if (isLoginRequired && isLoading) {
      return (
        <Layout title="Loading" hasTabBar>
          <Skeleton paragraph={{ rows: 8 }} />
        </Layout>
      );
    }
    if (isLoginRequired) {
      if (!isLogin) {
        return <UnauthorizedView name="login" link={window.location.href} />;
      }
      if (isReviewing && !isSettingPage) {
        return <UnauthorizedView name="review" />;
      }
      if (isVip) {
        return children;
      }
      if (!isActive && !isSettingPage) {
        return <UnauthorizedView name="active" />;
      }
      if (!hasProfile && !isSettingPage) {
        return <UnauthorizedView name="profile" />;
      }
    }
  }
  return children;
};

export default ProtectRoute;
