import Link from 'next/link';
import React from 'react';

import Layout from '~/components/Layout';
import { bbsEndpoint } from '~/utils/commons';

const ErrorMessage = (link = '/') => {
  return {
    profile: {
      message: '请先完善您的个人资料',
      left: {
        name: '随便看看',
        link: '/',
      },
      right: {
        name: '前往完善',
        link: '/settings/profile',
      },
      showVip: true,
    },
    ban: {
      message: '您的IP或账号已被禁用，无法访问该网站',
      left: {
        name: '退出登录',
        link: bbsEndpoint.LOGOUT(bbsEndpoint.ABOUT_US),
      },
      right: {
        name: '提交工单',
        link: 'https://www.1point3acres.com/contact',
      },
      showVip: true,
    },
    login: {
      message: '登录后即可查看更多精彩内容',
      left: {
        name: '注册账号',
        link: bbsEndpoint.REGISTER,
      },
      right: {
        name: '登录账号',
        link: bbsEndpoint.LOGIN(link),
      },
    },
    active: {
      message: '请先重新激活您的交友账号',
      left: {
        name: '随便看看',
        link: '/',
      },
      right: {
        name: '前往激活',
        link: '/settings',
      },
      showVip: true,
    },
    review: {
      message: '使用该功能，您需要进行公司学校邮箱认证',
      left: {
        name: '随便看看',
        link: '/',
      },
      right: {
        name: '前往认证',
        link: 'https://auth.1point3acres.com/tools/whitelist-email',
      },
      showVip: false,
    },
  };
};

const UnauthorizedView = ({ name, link }) => {
  const { message, left, right, showVip } = ErrorMessage(link)[name];
  return (
    <Layout hasTabBar>
      <section className="flex flex-col mt-20 md:mt-10">
        <div className="w-full text-center h-32">
          <img src="/images/love.svg" className="w-auto h-full mx-auto" />
        </div>
        <div className="text-center text-lg mt-4">{message}</div>
        <div className="text-center text-base mt-2 space-x-2">
          <Link href={left.link}>
            <a>{left.name}</a>
          </Link>
          <span className="text-brownish-grey">|</span>
          <Link href={right.link}>
            <a rel="nofollow">{right.name}</a>
          </Link>
        </div>
        {showVip && (
          <div className="mt-8 text-center text-base p-2 shadow">
            <Link href="/pricing/vip">
              <a>
                <span className="text-primary-200 underline">VIP用户</span>不受资料限制可以使用全部功能~
              </a>
            </Link>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default UnauthorizedView;
