export const GA_TRACKING_ID = 'UA-174939612-1'; // This is your GA Tracking ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  try {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  } catch (error) {
    // ignore
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  try {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  } catch (error) {
    // ignore
  }
};
